import { makeStyles, createStyles } from "@material-ui/core/styles";

const AttendeeItemStyles = makeStyles((theme: any) =>
  createStyles({
    attendeeItem: {
      width: "98.5%",
      height: 50,
      right: 1,
      marginTop: 4,
      position: "relative",
      textAlign: "left",
      backgroundColor: theme.palette.controlCenterButton.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    attendeeName: {
      position: "absolute",
      width: 217,
      top: 5,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      left: 26,
      fontSize: 16,
      fontWeight: 500,
    },
    attendeeNameDebug: {
      width: 183,
    },
    attendeeNameMobile: {
      width: "80%",
    },
    attendeeCompany: {
      fontSize: 12,
      position: "absolute",
      left: 26,
      top: 26,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 217,
      color: theme.palette.attendeeCompany.main,
    },
    expandedAttendeeHolder: {
      height: 131,
      "&:hover": {
        cursor: "pointer",
      },
    },
    attendeeCompanyMobile: {
      width: "80%",
    },
    attendeeNameExpanded: {
      position: "absolute",
      fontSize: 18,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 217,
      top: 6,
      left: 68,
    },
    attendeeNameExpandedDebug: {
      width: 155,
    },
    attendeeIconExpanded: {
      height: 40,
      width: 40,
      position: "absolute",
      marginLeft: 13,
      top: 10,
    },
    attendeeCompanyExpanded: {
      position: "absolute",
      fontSize: 14,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 217,
      top: 33,
      fontWeight: 500,
      left: 68,
    },
    sendMessageButton: {
      position: "absolute",
      top: 6,
      right: 8,
    },
    sendMessageButtonUp: {
      backgroundColor: theme.palette.sidebarHeader.main,
      borderRadius: "50%",
    },
    sendMessageButtonExpanded: {
      position: "absolute",
      top: 6,
      right: 8,
    },
    sendMessageButtonExpandedDebug: {
      top: 6,
    },
    sendMessageButtonTeleport: {
      position: "absolute",
      top: 6,
      right: 8,
    },
    sendMessageButtonTeleportDebug: {
      top: 9,
    },
    manageButton: {
      height: 30,
      width: 107,
      position: "absolute",
      left: 127,
      bottom: 17,
    },
    muteButton: {
      height: 30,
      width: 105,
      position: "absolute",
      left: 130,
      bottom: 10,
    },
    manageIcon: {},
    locationIconTeleport: {
      position: "absolute",
      left: 21,
      top: 9,
    },
    teleportMessage: {
      position: "relative",
      fontSize: 14,
      fontWeight: 400,
      left: 55,
      bottom: 3,
      letterSpacing: 0,
    },
    teleportMessageResult: {
      left: 55,
    },
    sendMessageButtonDebug: {
      top: 6,
    },
    teleportButton: {
      padding: 0,
      display: "inline-block",
      position: "relative",
      width: 258,
      height: 30,
      color: "white",
      fontSize: 14,
      left: 7,
    },
    teleportButtonMobile: {
      width: "90%",
      left: 25,
    },
    teleportButtonLabel: {
      position: "relative",
      display: "inline-block",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: 220,
      bottom: 4,
      right: 40,
      letterSpacing: 0,
      fontWeight: 400,
    },
    teleportButtonLabelUnavailable: {
      right: 26,
      color: "white !important",
    },
    buttonLabelMessageMobile: {
      position: "absolute",
      left: -35,
      bottom: "2px !important",
    },
    buttonLabelMessage: {
      bottom: -1,
    },
    arrowRightIcon: {
      marginRight: 18,
      marginLeft: 7,
      position: "relative",
      top: 6,
      left: 8,
    },
    messageIcon: {
      marginRight: 12,
      marginLeft: 7,
      position: "relative",
      top: 4,
      fontSize: "19.2px",
    },
    changeRoomIconButton: {
      padding: 6,
      position: "absolute",
      top: 7,
      right: 10,
      color: theme.palette.text.primary,
    },
    attendeeButtonsHolder: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      backgroundColor: theme.palette.sidebarHeader.main,
      height: 80,
      position: "relative",
      top: 51,
      width: "calc(100% - 10px)",
      left: 10,
    },
    attendeeButtonsHolderMobile: {
      left: -3,
      height: 57,
      width: "calc(100% + 3px)",
    },
    loadingSpinner: {
      borderWith: "3px !important",
    },
    expandedAttendeeHolderMobile: {
      height: 107,
    },
  })
);

export { AttendeeItemStyles };
