import { Button, Dialog, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import BoxIcon from "@material-ui/icons/Crop54";

import { MobileAudioUnlockPopupStyles } from "./MobileAudioUnlockPopupStyles";
import styles from "./MobileAudioUnlockPopup.module.css";
import { MobileContext, MobileContextType } from "../../../context/MobileContext";

type Props = {};

export default function MobileAudioUnlockPopup({}: Props) {
  const {
    showAudioUnlockDialog,
    handleShowAudioUnlock
  } : MobileContextType = useContext(MobileContext);

  const handleDialogClose = () => {
    handleShowAudioUnlock(false);
  };

  const handleUnlockAudio = () => {
    SHOWBOAT.UIEventManager.OnAudioUnlockEvent.Raise();

    handleShowAudioUnlock(false);
  };

  const classes = MobileAudioUnlockPopupStyles();

  return (
    <Dialog
      open={showAudioUnlockDialog}
      onClose={handleDialogClose}
      classes={{ paper: classes.audioUnlockPopup }}
      disableBackdropClick
    >
      <div className={styles.audioUnlockPopupWrapper}>
        <Typography className={classes.audioUnlockText}>
          Your browser is requesting permission to start the presentation feeds.
        </Typography>

        <Button
          className={classes.button}
          variant="contained"
          onClick={handleUnlockAudio}
          onTouchStart={handleUnlockAudio}
        >
          <BoxIcon className={classes.boxIcon} />
          START FEEDS
        </Button>
      </div>
    </Dialog>
  );
}
