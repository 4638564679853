import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  ReactElement,
} from "react";
import { Typography, Button, Card, IconButton, Box, fade } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";
import LocationIcon from "@material-ui/icons/LocationOn";
import EnterIcon from "@material-ui/icons/ExitToApp";
import $ from "jquery";
import MailIcon from "@material-ui/icons/Mail";
import { Ring } from "react-awesome-spinners";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import ArrowDownIcon from "@material-ui/icons/ExpandMore";
import ArrowUpIcon from "@material-ui/icons/ExpandLess";

import {
  AttendeeContext,
  AttendeeContextType,
} from "../../../../../context/AttendeeContext";
import {
  OneOnOneChatContext,
  OneOnOneChatContextType,
} from "../../../../../context/OneOnOneChatContext";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../../context/PresenterContext";
import { AppContext, AppContextType } from "../../../../../context/AppContext";
import AttendeeMuteIcon from "./AtendeeMuteIcon/AttendeeMuteIcon";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";

import styles from "./AttendeeItem.module.css";
import { AttendeeItemStyles } from "./AttendeeItemStyles";
import { ServerHelper } from "../../../../../Utilities/ServerHelper";
import { MobileContext, MobileContextType } from "../../../../../context/MobileContext";

interface Props {
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeCompany: string;
  attendeeUserID: string;
  attendeeFace: number;
  attendeeRoomID: string;
  attendeeMobile: boolean;
  attendeeAvailableForTeleport: boolean;
  handleSelect: (attendeeID: string) => any;
  currentlySelected: string;
  attendeeColor: number;
  roomSwitchVariant: boolean;
  mobile: boolean;
}

export default function AttendeeItem(props: Props) {
  //Context
  const { isBackstage, toggleIsOnStage, currentMode }: PresenterContextType =
    useContext(PresenterContext);

  const {
    messageSent,
    handleToggleIsOneOnOneChatOpen,
    setNameToMessage,
    setUserIDToMessage
  }: OneOnOneChatContextType = useContext(OneOnOneChatContext);

  const {
    setMobileShowSendPrivateMessage,
  } : MobileContextType = useContext(MobileContext);

  const { isDebugModeActive }: AppContextType = useContext(AppContext);

  const {
    previousTeleportRequestUserID,
    setPreviousTeleportRequestUserID,
    handleChangeRoomID,
  }: AttendeeContextType = useContext(AttendeeContext);

  //State
  const [teleportSent, toggleTeleportRequest] = useState(false);
  const [requestResponse, setRequestResponse] = useState("");
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [showVoiceActivity, toggleShowVoiceActivity] = useState(false);
  const [attendeeColor, setAttendeeColor] = useState(props.attendeeColor);
  const [attendeeFirstName, setAttendeeFirstName] = useState(
    props.attendeeFirstName
  );
  const [attendeeLastName, setAttendeeLastName] = useState(
    props.attendeeLastName
  );
  const [attendeeCompany, setAttendeeCompany] = useState(props.attendeeCompany);
  const [showComponent, toggleShowComponent] = useState(false);
  const [teleportAvailable, setTeleportAvailable] = useState(props.attendeeAvailableForTeleport);

  /*setTimeout refs */
  const autoDeclineTimeout = useRef(null);
  const autoDeclineMessageTimeout = useRef(null);
  const changeRoomTimeout = useRef(null);
  const changeToSameRoomTimeout = useRef(null);
  const declinedTimeout = useRef(null);
  const roomFullTimeout = useRef(null);
  const componentIsMounted = useRef(true);
  /**/

  useEffect(() => {
    //If this is a mobile user, set teleport unavailable
    if (props.attendeeMobile) {
      setTeleportAvailable(false);
    }
    
    //If debug mode is active, set up listener for volume change
    if (isDebugModeActive)
      SHOWBOAT.UIEventManager.OnRemotePlayerVolumeEvent.Add(
        props.attendeeUserID,
        handleAttendeeVolumeChange
      );

    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.attendeeUserID) !==
      undefined
    ) {
      //Check for load complete on the avatar. If so, show list item. If not complete, add listener
      if (
        SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.attendeeUserID)
          .loadComplete
      ) {
        toggleShowComponent(true);
      } else {
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
          props.attendeeUserID,
          SHOWBOAT.ChangeReason.LoadComplete,
          handleShowAttendeeListItem
        );
      }

      //Add listener for color change
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.ColorNumber,
        handleAttendeeColorChange
      );

      //Add listener for nametag change
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.NameTag,
        handleAttendeeNametagChange
      );

      //Add listener for Teleport Availability change
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.TeleportAvailability,
        handleAttendeeTeleportAvailabilityChange
      );
    }

    return function cleanup() {
      componentIsMounted.current = false;

      SHOWBOAT.UIEventManager.OnRemotePlayerVolumeEvent.Remove(
        props.attendeeUserID,
        handleAttendeeVolumeChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.LoadComplete,
        handleShowAttendeeListItem
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.ColorNumber,
        handleAttendeeColorChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.attendeeUserID,
        SHOWBOAT.ChangeReason.TeleportAvailability,
        handleAttendeeTeleportAvailabilityChange
      );
      
      if (autoDeclineTimeout.current) {
        clearTimeout(autoDeclineTimeout.current);
      }
      if (changeRoomTimeout.current) {
        clearTimeout(changeRoomTimeout.current);
      }
      if (declinedTimeout.current) {
        clearTimeout(declinedTimeout.current);
      }
      if (changeToSameRoomTimeout.current) {
        clearTimeout(changeToSameRoomTimeout.current);
      }
      if (autoDeclineMessageTimeout.current) {
        clearTimeout(autoDeclineMessageTimeout.current);
      }
      if (roomFullTimeout.current) {
        clearTimeout(roomFullTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    //Listen for if debug mode becomes active. If so, add volume change listeners
    if (isDebugModeActive) {
      SHOWBOAT.UIEventManager.OnRemotePlayerVolumeEvent.Add(
        props.attendeeUserID,
        handleAttendeeVolumeChange
      );
    } else {
      //If debug mode is deactivated, remove the listener
      SHOWBOAT.UIEventManager.OnRemotePlayerVolumeEvent.Remove(
        props.attendeeUserID,
        handleAttendeeVolumeChange
      );
    }
  }, [isDebugModeActive]);

  //Show the avatar list item once done loading in
  const handleShowAttendeeListItem = () => {
    toggleShowComponent(true);
  };

  //Listener for attendee color changes
  const handleAttendeeColorChange = (avatarData: SHOWBOAT.AvatarData) => {
    //Override props value with new color
    setAttendeeColor(avatarData.color);
  };

  const handleAttendeeNametagChange = (avatarData) => {
    //Override props first name/last name/company with new data
    setAttendeeFirstName(avatarData.firstName);
    setAttendeeLastName(avatarData.lastName);
    setAttendeeCompany(avatarData.company);
  };

  const handleAttendeeTeleportAvailabilityChange = (avatarData: SHOWBOAT.AvatarData) => {
    setTeleportAvailable(avatarData.isAvailableForTeleport);
  }

  //Volume change handler for debug mode
  const handleAttendeeVolumeChange = (volume: number) => {
    if (componentIsMounted.current) {
      if (volume > 0.002 && !showVoiceActivity) {
        toggleShowVoiceActivity(true);
      } else if (volume < 0.002 && toggleShowVoiceActivity) {
        toggleShowVoiceActivity(false);
      }
    }
  };

  /*Show expanded attendee item when clicked */
  const handleAttendeeSelect = (attendeeID: string) => {
    //Do nothing if we are in room switch variant
    if (props.roomSwitchVariant) return;

    props.handleSelect(attendeeID);
  };
  /**/

  /* Teleport Request Logic */
  const handleTeleportRequestClick = (e: any) => {
    let attendeeAvatarData: SHOWBOAT.AvatarData =
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.attendeeUserID);

    //Ensure attendee is still in event before sending request to them
    if (attendeeAvatarData !== undefined) {
      //If we are backstage, disable the request unless other attendee is also backstage
      if (
        isBackstage &&
        SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.attendeeUserID)
          .partition !== StringVariableHelper.ShowboatPartitions.backstage
      ) {
        //Raise error
        SHOWBOAT.UIEventManager.OnUIError.Raise(
          "Teleporting to this person is disabled while you are backstage"
        );

        return;
      }

      //socket server hack to force decline/end any previous requests
      SHOWBOAT.SocketIOController.OnTeleport.Raise({
        teleportAccepted: false,
        toRoom: "",
        toUserID: previousTeleportRequestUserID,
        autoResponse: true,
      });

      //set previous request userID in context
      setPreviousTeleportRequestUserID(props.attendeeUserID);

      toggleTeleportRequest(true);

      SHOWBOAT.SocketIOController.CreateTeleportRequest(props.attendeeUserID);

      toggleLoadingSpinner(true);

      SHOWBOAT.SocketIOController.OnTeleport.Add(handleTeleportRequestResponse);

      //auto-hide teleport request after 60 seconds

      autoDeclineTimeout.current = setTimeout(() => {
        SHOWBOAT.SocketIOController.OnTeleport.Remove(
          handleTeleportRequestResponse
        );
        toggleLoadingSpinner(false);
        setRequestResponse("missed");

        autoDeclineMessageTimeout.current = setTimeout(() => {
          setRequestResponse("");
          toggleTeleportRequest(false);
        }, 3000);
      }, 30000);

      if (!e) {
        var event: any = window.event;
        event.cancelBubble = true;
        if (event.stopPropagation) {
          event.stopPropagation();
        }
      }
      e.cancelBubble = true;
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    } else {
      //Otherwise, just deselect attendee and do nothing
      props.handleSelect(null);
      return;
    }
  };

  const handleTeleportRequestResponse = (teleport: SHOWBOAT.Teleport) => {
    toggleLoadingSpinner(false);

    //If response is not from correct userID, shoot it down
    if (props.attendeeUserID !== teleport.toUserID) {
      clearTimeout(autoDeclineTimeout.current);

      toggleTeleportRequest(false);

      setRequestResponse("");

      props.handleSelect(null);

      SHOWBOAT.SocketIOController.OnTeleport.Remove(
        handleTeleportRequestResponse
      );

      return;
    } else {
      //request accepted
      if (teleport.teleportAccepted === true) {
        //show accepted message
        setRequestResponse("accepted");

        //clear auto-decline timeout
        clearTimeout(autoDeclineTimeout.current);

        //remove listener
        SHOWBOAT.SocketIOController.OnTeleport.Remove(
          handleTeleportRequestResponse
        );

        //check if trying to teleport to same room
        if (
          teleport.toRoom === SHOWBOAT.LocalAvatarDataManager.avatarData.roomID
        ) {
          //toggle isOnStage to false
          toggleIsOnStage(false);

          SHOWBOAT.UIEventManager.OnTeleport.Raise(teleport.toUserID);

          //Delay to show the message
          changeToSameRoomTimeout.current = setTimeout(function () {
            setRequestResponse("");
            toggleTeleportRequest(false);
          }, 3000);
        } else {
          //we need to change rooms
          SHOWBOAT.UIEventManager.RoomChangeInProgress = true;
          SHOWBOAT.SocketIOController.ChangeToSpecificRoom(teleport.toRoom)
            .then((response) => {
              //room full, etc
              if (response.success === false) {
                SHOWBOAT.UIEventManager.OnUIError.Raise("Room is full");
                SHOWBOAT.UIEventManager.RoomChangeInProgress = false;

                //Hide message after 3 seconds
                changeRoomTimeout.current = setTimeout(function () {
                  setRequestResponse("");
                  toggleTeleportRequest(false);
                }, 3000);
              } else if (response.success === true) {
                //toggle isOnStage to false
                toggleIsOnStage(false);

                SHOWBOAT.LocalAvatarDataManager.roomID = teleport.toRoom;
                SHOWBOAT.UIEventManager.TeleportPending = true;
                SHOWBOAT.UIEventManager.OnRoomChange.Raise(teleport.toRoom);
                SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
                handleChangeRoomID(teleport.toRoom);

                SHOWBOAT.UIEventManager.OnTeleport.Raise(teleport.toUserID);
                SHOWBOAT.UIEventManager.TeleportPending = false;

                changeRoomTimeout.current = setTimeout(function () {
                  //render default expanded attendee display
                  setRequestResponse("");

                  //toggle teleport request for this attendee to false
                  toggleTeleportRequest(false);

                  //collapse this selected attendee
                  props.handleSelect(null);
                }, 3000);
              }
            })
            .catch((e) => {
              SHOWBOAT.Logger.Error("AttendeeItem.ChangeToSpecificRoom", e);
              SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
            });
        }
      }

      //Request was declined due to autoResponse (not allowing multiple requests)
      else if (
        teleport.teleportAccepted === false &&
        teleport.autoResponse === true &&
        !teleport.roomFull
      ) {
        //clear auto-decline timeout
        clearTimeout(autoDeclineTimeout.current);

        SHOWBOAT.SocketIOController.OnTeleport.Remove(
          handleTeleportRequestResponse
        );

        toggleTeleportRequest(false);
        setRequestResponse("");
      }

      //request was declined due to room being full
      else if (
        teleport.teleportAccepted === false &&
        teleport.roomFull === true &&
        teleport.autoResponse === true
      ) {
        //clear auto-decline timeout
        clearTimeout(autoDeclineTimeout.current);

        setRequestResponse("roomFull");

        SHOWBOAT.SocketIOController.OnTeleport.Remove(
          handleTeleportRequestResponse
        );

        //close teleport request after 2 seconds
        roomFullTimeout.current = setTimeout(function () {
          toggleTeleportRequest(false);
          setRequestResponse("");
        }, 3000);
      }

      //request declined by other attendee
      else if (
        teleport.teleportAccepted === false &&
        teleport.autoResponse === false &&
        teleport.roomFull === false
      ) {
        //clear auto-decline timeout
        clearTimeout(autoDeclineTimeout.current);

        //show declined message
        setRequestResponse("declined");

        //remove listener
        SHOWBOAT.SocketIOController.OnTeleport.Remove(
          handleTeleportRequestResponse
        );

        //close teleport request after 2 seconds
        declinedTimeout.current = setTimeout(function () {
          toggleTeleportRequest(false);
          setRequestResponse("");
        }, 3000);
      }
    }
  };
  /**/

  /* 1-on-1 chat logic */
  const handleSendMessageClick = (e: any) => {

    //Switch logic based on if we are on mobile or not
    if (ServerHelper.useMobileApp) {
      clickAndFocusTextField();

      //Set this user in context for the to-send user
      setNameToMessage(`${props.attendeeFirstName} ${props.attendeeLastName}`)
      //Set this user's userID in context 
      setUserIDToMessage(props.attendeeUserID);
      //Show private message overlay
      setMobileShowSendPrivateMessage(true);
    } else {
      if (messageSent) {
        $("#privateChatMessageHolder").animate(
          {
            bottom: `+=70`,
          },
          300
        );
      }

      handleToggleIsOneOnOneChatOpen(
        props.attendeeUserID,
        `${attendeeFirstName} ${attendeeLastName}`
      );

      //Undefined check for e parameter
      if (!e) {
        var event: any = window.event;
        event.cancelBubble = true;
        if (event.stopPropagation) {
          event.stopPropagation();
        }
      }
      e.cancelBubble = true;
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    }
  };
  /**/

  const clickAndFocusTextField = () => {
    let textField =  document.getElementById("mobileNewPrivateMessageTextField")

    if (textField) {
      //Ensure the text field is visible
      textField.style.visibility = "visible";
      textField.focus();
      textField.click();
    }
  };

  //Change room to attendee
  const handleChangeRoomToAttendeeClick = (e: any) => {
    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.attendeeUserID) ===
      undefined
    ) {
      SHOWBOAT.UIEventManager.OnUIError.Raise("Attendee has left the event");

      e.stopPropogation();
    } else {
      let roomID = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(
        props.attendeeUserID
      ).roomID;

      SHOWBOAT.UIEventManager.RoomChangeInProgress = true;
      SHOWBOAT.SocketIOController.ChangeToSpecificRoom(roomID)
        .then((response) => {
          if (response.success === false) {
            SHOWBOAT.UIEventManager.OnUIError.Raise("Sorry, room is full!");
            SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
          } else {
            //toggle on stage to false if mode is not presenter
            if (
              currentMode !== StringVariableHelper.ShowboatModeType.presenter
            ) {
              toggleIsOnStage(false);
            }
            SHOWBOAT.LocalAvatarDataManager.roomID = roomID;
            SHOWBOAT.UIEventManager.OnRoomChange.Raise(roomID);
            SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
            handleChangeRoomID(roomID);
          }
        })
        .catch((e) => {
          SHOWBOAT.Logger.Error("AttendeeItem.ChangeToSpecificRoom", e);
          SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
        });
    }

    //Undefined check for e parameter
    if (!e) {
      var event: any = window.event;
      event.cancelBubble = true;
      if (event.stopPropagation) {
        event.stopPropagation();
      }
    }
    e.cancelBubble = true;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  //Material UI classes
  const classes = AttendeeItemStyles();

  /* Teleport request status text */

  let teleportStatusText: ReactElement;

  if (requestResponse === "accepted") {
    teleportStatusText = (
      <Typography
        classes={{
          root: `${classes.teleportMessage} ${classes.teleportMessageResult}`,
        }}
      >
        TELEPORT REQUEST ACCEPTED
      </Typography>
    );
  } else if (requestResponse === "declined") {
    teleportStatusText = (
      <Typography
        classes={{
          root: `${classes.teleportMessage} ${classes.teleportMessageResult}`,
        }}
      >
        TELEPORT REQUEST DECLINED
      </Typography>
    );
  } else if (requestResponse === "missed") {
    teleportStatusText = (
      <Typography classes={{ root: classes.teleportMessage }}>
        TELEPORT REQUEST MISSED
      </Typography>
    );
  } else if (requestResponse === "roomFull") {
    teleportStatusText = (
      <Typography classes={{ root: classes.teleportMessage }}>
        DESTINATION ROOM FULL
      </Typography>
    );
  } else {
    teleportStatusText = (
      <Typography classes={{ root: classes.teleportMessage }}>
        TELEPORT REQUEST SENT
      </Typography>
    );
  }
  /**/

  return (
    <React.Fragment>
      <Card
        id={props.attendeeUserID}
        className={
          props.currentlySelected === props.attendeeUserID
            ? props.mobile
              ? `${classes.attendeeItem} ${classes.expandedAttendeeHolder} ${classes.expandedAttendeeHolderMobile}`
              : `${classes.attendeeItem} ${classes.expandedAttendeeHolder}`
            : classes.attendeeItem
        }
        onClick={() =>
          handleAttendeeSelect(
            props.attendeeUserID === props.currentlySelected
              ? null
              : props.attendeeUserID
          )
        }
        style={{
          display: showComponent ? "inline-block" : "none",
        }}
      >
        {!props.mobile && (
          <div
            className={styles.attendeeColorDisplay}
            style={{
              backgroundColor:
                SHOWBOAT.ApplicationSkin.primaryAvatarColors[attendeeColor],
            }}
          ></div>
        )}

        <span className={props.mobile ? styles.infoSpan : null}>
          <Typography
            variant="h6"
            classes={{
              root: isDebugModeActive
                ? `${classes.attendeeName} ${classes.attendeeNameDebug}`
                : props.mobile
                ? `${classes.attendeeName} ${classes.attendeeNameMobile}`
                : classes.attendeeName,
            }}
          >
            {attendeeFirstName} {attendeeLastName}
          </Typography>
          <Typography
            variant="body2"
            classes={{
              root: props.mobile
                ? `${classes.attendeeCompany} ${classes.attendeeCompanyMobile}`
                : classes.attendeeCompany,
            }}
          >
            {attendeeCompany}
          </Typography>
        </span>

        {props.currentlySelected === props.attendeeUserID ? (
          <Box
            className={
              props.mobile
                ? `${classes.attendeeButtonsHolder} ${classes.attendeeButtonsHolderMobile}`
                : classes.attendeeButtonsHolder
            }
          >
            {!ServerHelper.useMobileApp && !teleportAvailable ? (
              <React.Fragment>
                <Button
                  classes={{
                    root: classes.teleportButton,
                    label: `${classes.teleportButtonLabel} ${classes.teleportButtonLabelUnavailable}`,
                  }}
                  onClick={handleTeleportRequestClick}
                  variant="text"
                  disabled
                >
                  <CancelIcon classes={{ root: classes.arrowRightIcon }} />
                  TELEPORT UNAVAILABLE
                </Button>
              </React.Fragment>
            ) : teleportSent ? (
              <React.Fragment>
                {!props.mobile && (
                  <>
                    <div className={styles.loadingSpinnerHolder}>
                      {loadingSpinner && (
                        <Ring
                          size={25}
                          color={
                            SHOWBOAT.ApplicationSkin.theme === "light"
                              ? "black"
                              : "white"
                          }
                          className={classes.loadingSpinner}
                        />
                      )}
                    </div>
                    <div>
                      {!loadingSpinner && (
                        <RightArrowIcon
                          classes={{ root: classes.locationIconTeleport }}
                        />
                      )}
                    </div>
                    <div>{teleportStatusText}</div>
                  </>
                )}
              </React.Fragment>
            ) : (
              <>
                {!props.mobile && (
                  <Button
                    classes={{
                      root: classes.teleportButton,
                      label: classes.teleportButtonLabel,
                    }}
                    onClick={handleTeleportRequestClick}
                    variant="text"
                  >
                    <RightArrowIcon className={classes.arrowRightIcon} />
                    TELEPORT REQUEST
                  </Button>
                )}
              </>
            )}

            <Button
              classes={{
                root: ServerHelper.useMobileApp
                  ? `${classes.teleportButton} ${classes.teleportButtonMobile}`
                  : classes.teleportButton,
                label: ServerHelper.useMobileApp
                  ? `${classes.teleportButtonLabel} ${classes.buttonLabelMessage} ${classes.buttonLabelMessageMobile}`
                  : `${classes.teleportButtonLabel} ${classes.buttonLabelMessage}`,
              }}
              onClick={handleSendMessageClick}
              variant="text"
            >
              <MailIcon className={classes.messageIcon} />
              PRIVATE MESSAGE
            </Button>
          </Box>
        ) : null}

        {/* If we are in the room switch tab, show room switch button instead of message */}
        {props.roomSwitchVariant ? (
          <IconButton
            className={classes.changeRoomIconButton}
            onClick={handleChangeRoomToAttendeeClick}
            disabled={
              SHOWBOAT.LocalAvatarDataManager.roomID === props.attendeeRoomID
            }
          >
            <EnterIcon />
          </IconButton>
        ) : props.currentlySelected === props.attendeeUserID ? (
          <ArrowUpIcon
            className={
              isDebugModeActive
                ? `${classes.sendMessageButton} ${classes.sendMessageButtonDebug}`
                : `${classes.sendMessageButton} ${classes.sendMessageButtonUp}`
            }
          />
        ) : (
          <ArrowDownIcon
            className={
              isDebugModeActive
                ? `${classes.sendMessageButton} ${classes.sendMessageButtonDebug}`
                : `${classes.sendMessageButton}`
            }
          />
        )}

        {/* Show mute icon and voice activity indicator if debug mode is active */}
        {isDebugModeActive && !props.roomSwitchVariant && (
          <>
            <AttendeeMuteIcon
              userID={props.attendeeUserID}
              showVoiceActivity={showVoiceActivity}
            />
          </>
        )}
      </Card>
    </React.Fragment>
  );
}
