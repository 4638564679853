import React, { useEffect, useState, useRef } from "react";
import { MobilePresenterStreamHelper } from "../Utilities/MobilePresenterStreamHelper";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import { ServerHelper } from "../Utilities/ServerHelper";

export interface MobileContextType {
  currentMobileMode: string;
  setCurrentMobileMode: (mobileMode: string) => void;
  mobileJumbotronView: boolean;
  setMobileJumbotronView: (mobileJumbotronView: boolean) => void;
  showNewMessage: boolean;
  setShowNewMessage: (showNewMessage: boolean) => void;
  mobileIntakeCompleted: boolean;
  setMobileIntakeCompleted: (mobileIntakeCompleted: boolean) => void;
  showMobileSettings: boolean;
  setShowMobileSettings: (showMobileSettings: boolean) => void;
  showMobileLeaveMeeting: boolean;
  setShowMobileLeaveMeeting: (showMobileLeaveMeeting: boolean) => void;
  showNewQAItem: boolean;
  setShowNewQAItem: (showNewQAItem: boolean) => void;
  mobileJumbotronFullscreen: boolean;
  setMobileJumbotronFullscreen: (mobileJumbotronFullscreen: boolean) => void;
  mobileShowSendPrivateMessage: boolean;
  setMobileShowSendPrivateMessage: (
    mobileShowSendPrivateMessage: boolean
  ) => void;
  privateMessageCount: number;
  setPrivateMessageCount: (privateMessageCount: number) => void;
  mobileJumbotronButtonPressed: boolean;
  setMobileJumbotronButtonPressed: (
    mobileJumbotronButtonPressed: boolean
  ) => void;
  mobilePrivateMessages: any;
  setMobilePrivateMessages: (mobilePrivateMessages: Array<any>) => void;
  decrementMessageCount: () => void;
  jumbotronContentType: string;
  setJumbotronContentType: (jumbotronContentType: string) => void;
  jumbotronElement: any;
  setJumbotronElement: (jumbotronElement: any) => void;
  jumbotronElementSrc: string;
  setJumbotronElementSrc: (jumbotronElementSrc: string) => void;
  showAudioUnlockDialog: boolean;
  setShowAudioUnlockDialog: (showAudioUnlockDialog: boolean) => void;
  audioUnlockPressedForJumbotron: boolean;
  setAudioUnlockPressedForJumbotron: (pressed: boolean) => void;
  audioUnlockPressedForPresenters: boolean;
  setAudioUnlockPressedForPresenters: (pressed: boolean) => void;
  handleShowAudioUnlock: (show: boolean, origin?: string) => void;
}

export const MobileContext = React.createContext(null);

export const MobileContextProvider = (props) => {
  const [currentMobileMode, setCurrentMobileMode] = useState(
    StringVariableHelper.MobileModeValues.chat
  );
  const [mobileJumbotronView, setMobileJumbotronView] = useState(ServerHelper.useMobileHybridApp ? false : true);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [mobileIntakeCompleted, setMobileIntakeCompleted] = useState(false);
  const [showMobileLeaveMeeting, setShowMobileLeaveMeeting] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  const [showNewQAItem, setShowNewQAItem] = useState(false);
  const [mobileJumbotronFullscreen, setMobileJumbotronFullscreen] =
    useState(false);
  const [mobileShowSendPrivateMessage, setMobileShowSendPrivateMessage] =
    useState(false);
  const [privateMessageCount, setPrivateMessageCount] = useState(0);
  const [mobileJumbotronButtonPressed, setMobileJumbotronButtonPressed] =
    useState(false);
  const [mobilePrivateMessages, setMobilePrivateMessages] = useState([]);
  const [jumbotronContentType, setJumbotronContentType] = useState("");
  const [jumbotronElement, setJumbotronElement] = useState(null);
  const [jumbotronElementSrc, setJumbotronElementSrc] = useState(null);
  const [showAudioUnlockDialog, setShowAudioUnlockDialog] = useState(false);

  const [audioUnlockPressedForPresenters, setAudioUnlockPressedForPresenters] =
    useState(false);
  const [audioUnlockPressedForJumbotron, setAudioUnlockPressedForJumbotron] =
    useState(false);

  const [currentAudioUnlockOrigin, setCurrentAudioUnlockOrigin] = useState("");

  const msgID = useRef(0);
  const messageCountRef = useRef(0);
  
  const audioUnlockPressedForJumbotronRef = useRef(false);

  useEffect(() => {
    //Add listener for permanent disconnection - reload if event comes through
    if (ServerHelper.useMobileApp) {
      SHOWBOAT.SocketIOController.OnUserPermanentlyDisconnected.Add(handleUserPermanentlyDisconnected);
    }

    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      "chat",
      handleReceivePrivateMessage
    );

    MobilePresenterStreamHelper.OnRequestAudioUnlockScreen.Add(
      handleRequestAudioUnlock
    );

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnPrivateMessage.Remove(
        "chat",
        handleReceivePrivateMessage
      );

      MobilePresenterStreamHelper.OnRequestAudioUnlockScreen.Remove(
        handleRequestAudioUnlock
      );

      if (ServerHelper.useMobileApp) {
        SHOWBOAT.SocketIOController.OnUserPermanentlyDisconnected.Remove(
          handleUserPermanentlyDisconnected
        );
      }
    };
  }, []);

  useEffect(() => {
    audioUnlockPressedForJumbotronRef.current = audioUnlockPressedForJumbotron;
  }, [audioUnlockPressedForJumbotron]);

  useEffect(() => {
    if (showMobileSettings) {
      setShowMobileLeaveMeeting(false);
    }
  }, [showMobileSettings]);

  useEffect(() => {
    if (showMobileLeaveMeeting) {
      setShowMobileSettings(false);
    }
  }, [showMobileLeaveMeeting]);

  const handleUserPermanentlyDisconnected = () => {
    window.location.reload();
  }

  const handleRequestAudioUnlock = () => {
    setShowAudioUnlockDialog(true);
  };

  const decrementMessageCount = () => {
    setPrivateMessageCount(messageCountRef.current - 1);
    messageCountRef.current = messageCountRef.current - 1;
  };

  const handleReceivePrivateMessage = (data: SHOWBOAT.SocketMessage) => {
    let newMsg = {
      userID: data.userID,
      message: data.message,
      id: msgID.current,
    };

    setMobilePrivateMessages((mobilePrivateMessages) => [
      ...mobilePrivateMessages,
      newMsg,
    ]);

    msgID.current = msgID.current + 1;

    setPrivateMessageCount(messageCountRef.current + 1);
    messageCountRef.current = messageCountRef.current + 1;
  };

  const handleShowAudioUnlock = (show: boolean, origin: string) => {
    setShowAudioUnlockDialog(show);
    if (show) {
      //Set the origin when we show the popup
      setCurrentAudioUnlockOrigin(origin);
    } else {
      //Set current boolean depending on the current origin
      currentAudioUnlockOrigin === "presenters"
        ? setAudioUnlockPressedForPresenters(true)
        : setAudioUnlockPressedForJumbotron(true);
    }
  };

  let val: MobileContextType = {
    currentMobileMode,
    setCurrentMobileMode,
    mobileJumbotronView,
    setMobileJumbotronView,
    showNewMessage,
    setShowNewMessage,
    mobileIntakeCompleted,
    setMobileIntakeCompleted,
    showMobileLeaveMeeting,
    setShowMobileLeaveMeeting,
    showMobileSettings,
    setShowMobileSettings,
    showNewQAItem,
    setShowNewQAItem,
    mobileJumbotronFullscreen,
    setMobileJumbotronFullscreen,
    mobileShowSendPrivateMessage,
    setMobileShowSendPrivateMessage,
    privateMessageCount,
    setPrivateMessageCount,
    mobileJumbotronButtonPressed,
    setMobileJumbotronButtonPressed,
    mobilePrivateMessages,
    setMobilePrivateMessages,
    decrementMessageCount,
    jumbotronContentType,
    setJumbotronContentType,
    jumbotronElement,
    setJumbotronElement,
    jumbotronElementSrc,
    setJumbotronElementSrc,
    showAudioUnlockDialog,
    setShowAudioUnlockDialog,
    audioUnlockPressedForJumbotron,
    setAudioUnlockPressedForJumbotron,
    audioUnlockPressedForPresenters,
    setAudioUnlockPressedForPresenters,
    handleShowAudioUnlock,
  };

  return (
    <MobileContext.Provider value={val}>
      {props.children}
    </MobileContext.Provider>
  );
};
