import React, {useState, useEffect, useRef} from 'react';

export interface MessageContextType {
  chatMessages : Array<SHOWBOAT.SocketMessage>,
  setMessages : (messages : Array<SHOWBOAT.SocketMessage>) => void,
  alerts: Array<SHOWBOAT.SocketMessage>,
  setAlerts: (alerts: Array<SHOWBOAT.SocketMessage>) => void,
  newMessage : boolean,
  toggleNewMessage : (newMessage : boolean) => void
}

export const MessageContext = React.createContext(null);

export const MessageContextProvider = (props) => {

  const [ chatMessages, setMessages ] = useState([]);
  const [ alerts, setAlerts ] = useState([]);
  const [ newMessage, toggleNewMessage ] = useState(false);

  const chatMessagesRef = useRef([]);

  let msgID : number = 0;

  useEffect(() => {
    //append to chat array when message added
    SHOWBOAT.SocketIOController.OnEventMessage.Add(
      "chat",
      handleOnGlobalChatMessage
    );

    //append to global broadcast array when broadcast occurs
    SHOWBOAT.SocketIOController.OnEventMessage.Add(
      "alert",
      handleOnGlobalAlertMessage
    );

    //append to personal chat array when a message is received

    //cleanup listeners

    //Listen for nametag changes
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
      SHOWBOAT.ChangeReason.NameTag,
      handlePlayerNameTagChange
    );

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnEventMessage.Remove(
        "chat",
        handleOnGlobalChatMessage
      );

      SHOWBOAT.SocketIOController.OnEventMessage.Remove(
        "alert",
        handleOnGlobalAlertMessage
      );

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Remove(
        SHOWBOAT.ChangeReason.NameTag,
        handlePlayerNameTagChange
      );
    };
  }, []);

  useEffect(() => {
    if (chatMessages.length >= 50) {

      chatMessages.shift();

      setMessages(chatMessages)
    }
  }, [chatMessages])


  /*Message handlers */

  const handlePlayerNameTagChange = (avatarData: SHOWBOAT.AvatarData) => {

    let messagesClone = [...chatMessagesRef.current];

    for (let i = 0; i < messagesClone.length; i++) {
      if (messagesClone[i].userID === avatarData.userID) {
        messagesClone[i].message.name = `${avatarData.firstName} ${avatarData.lastName}`;
      } 
    } 

    setMessages(messagesClone);
    chatMessagesRef.current = messagesClone;
  }

  const handleOnGlobalChatMessage = (data: SHOWBOAT.SocketMessage) => {

    toggleNewMessage(true);

    let newMessage = {
      userID: data.userID,
      message: data.message,
      id: msgID
    }

    setMessages(chatMessages => [...chatMessages, newMessage]);
    chatMessagesRef.current = [...chatMessagesRef.current, newMessage];

    msgID = msgID + 1;
    
  };

  const handleOnGlobalAlertMessage = (data: SHOWBOAT.SocketMessage) => {
    setAlerts(alerts => [...alerts, data])
  }
  /**/

  const messagesProviderValue : MessageContextType = {
    chatMessages,
    setMessages,
    alerts,
    setAlerts,
    newMessage,
    toggleNewMessage
  }

  return (
    <MessageContext.Provider
      value={messagesProviderValue}
    >
      {props.children}
    </MessageContext.Provider>
  )
}